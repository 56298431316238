import axios from 'axios';
// import { API } from '../api';
import { IWebForm, WebformsSubmission } from '../interface/IWebform';

class WebFormService {
  private api = `http://localhost:8081/v3/webforms`;

  public createWebformSubmission = (formID: string, payload: WebformsSubmission, apiURL: string) =>
    axios.post<{ data: IWebForm }>(`${apiURL}/api/v3/webforms/${formID}/submission`, payload);

  public getWebForm = (url: string, apiURL: string) =>
    axios.get<{ data: IWebForm }>(`${apiURL}/api/v3/webforms?${url}`);
}

export default WebFormService;

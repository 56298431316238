import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  FormControl,
  FormLabel,
  GridItem,
  Heading,
  Image,
  Input,
  Link,
  Select,
  SimpleGrid,
  Spinner,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { FieldArray, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { AppConfig } from '../../core/app.config';
import { IInputField, IWebForm, WebformsSubmission } from '../../core/interface/IWebform';
import WebFormService from '../../core/services/service.webform';

interface MyFormValues {
  name: string;
  mail_ids: {
    email: string;
  }[];
  phone_number: string;
  service_id: string;
  tags: string[];
  incident_title: string;
  incident_description: string;
  attachment: File | null;
}
const initialValues: MyFormValues = {
  name: '',
  phone_number: '',
  mail_ids: [
    {
      email: ' ',
    },
  ],
  service_id: '',
  tags: [],
  incident_title: '',
  incident_description: '',
  attachment: null,
};

const WebForm = () => {
  const webformServices = new WebFormService();
  const fileRef = useRef<HTMLInputElement | null>(null);
  const [webform, setWebform] = useState<IWebForm>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>({});
  const [isSuccess, setSuccess] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [captchaToken, setCaptchaToken] = useState<string>('');
  const [resetKey, setResetKey] = useState<number>(0);

  let url = window.location.host + window.location.pathname;
  const apiURL = window.location.origin;
  const isHostedURL = url.indexOf(AppConfig.webforms_url) !== -1;

  useEffect(() => {
    if (isHostedURL) {
      url = `publicUrl=${url}`;
    } else {
      url = `hostName=${window.location.host}`;
    }
    getWebformData(url, apiURL);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setSuccess(false);
    }, 2000);
  }, [isSuccess]);

  const getWebformData = async (url: string, apiURL: string) => {
    const errors: any = {};
    try {
      const {
        data: { data: webform },
      } = await webformServices.getWebForm(url, apiURL);
      setWebform(webform);
    } catch (err: any) {
      console.log(err);
      errors.get = err.response.data.meta.error_message;
      setError(errors);
    }
    setIsLoading(false);
  };

  const handleReset = () => {
    if (!window.confirm('Reset?')) {
      throw new Error('Cancel reset');
    }
  };

  const onloadCallback = (token: string | null) => {
    setCaptchaToken(token ? token : '');
    setIsDisabled(false);
  };

  const handleCaptchaExpired = () => {
    setCaptchaToken('');
    setIsDisabled(true);
  };

  const submitform = async (values: any) => {
    const errors: any = {};
    try {
      const email = values.mail_ids.map((e: any) => {
        return e.email;
      });
      const payload: WebformsSubmission = {
        name: values.name,
        phone_number: values.phone_number,
        mail_ids: email,
        service_id: values.service_id,
        tags: values.tags,
        incident_title: values.incident_title,
        incident_description: values.incident_description,
        attachment: values.attachment,
        token: captchaToken,
      };
      await webformServices.createWebformSubmission(webform?.form_id || '', payload, apiURL);
      setSuccess(true);
      setError({});
    } catch (err: any) {
      console.log(err);
      errors.submit = 'Unable to submit, try again';
      setError(errors);
    } finally {
      setCaptchaToken('');
      setIsDisabled(true);
      setResetKey(prevKey => prevKey + 1);
    }
  };

  function validateUrl(url: string) {
    const validUrl = new RegExp(
      /^(?:(http(s)?:\/\/|mailto:))[\w.-]+(?:.[\w.-]+)+[\w-._~:/?#[\]@!$&'()*+,;=.]+$/g,
    );
    return validUrl.test(url);
  }

  return (
    <>
      {isLoading ? (
        <Flex align="center" justify="center">
          <Center h="720px">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            ></Spinner>
          </Center>
        </Flex>
      ) : webform ? (
        <Flex bg="white" align="center" justify="center">
          <Box
            bg="#EDF2F759"
            p={6}
            rounded="md"
            boxShadow={'0 4px 11px 0 rgb(0 0 0 / 6%)'}
            marginTop="25px"
          >
            {webform.logo_url && (
              <Flex justify="center" marginBottom="10px">
                <Image src={webform.logo_url} alt="logo" width="150px" height="150px" />
              </Flex>
            )}

            <Formik
              initialValues={initialValues}
              onSubmit={(values, { resetForm }) => {
                submitform(values);
                resetForm({ values: initialValues });
              }}
            >
              {formik => {
                const { handleSubmit, values, handleChange, setFieldValue, resetForm } = formik;
                return (
                  <Form onSubmit={handleSubmit}>
                    <VStack spacing={4} align="flex-start">
                      <Box textAlign="center" w="100%">
                        <Heading>{webform.header}</Heading>
                        <Text fontSize="md" w="80%" display="inline-block">
                          {webform.description}
                        </Text>
                      </Box>
                      <SimpleGrid columns={2} spacing={4} w={'100%'}>
                        <GridItem colSpan={1}>
                          <FormControl isRequired>
                            <FormLabel htmlFor="name">Name </FormLabel>
                            <Input
                              id="name"
                              name="name"
                              type="text"
                              variant="filled"
                              value={values.name}
                              onChange={handleChange}
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem colSpan={1}>
                          <FormControl>
                            <FormLabel htmlFor="phone">Phone</FormLabel>
                            <Input
                              id="Phone"
                              name="phone_number"
                              type="text"
                              variant="filled"
                              value={values.phone_number}
                              onChange={handleChange}
                            />
                          </FormControl>
                        </GridItem>
                      </SimpleGrid>
                      <FieldArray name="mail_ids">
                        {({ insert, remove, push }) => (
                          <div style={{ width: '100%' }}>
                            {values.mail_ids.length > 0 &&
                              values.mail_ids.map((email, index) => (
                                <div key={index} style={{ marginBottom: '5px' }}>
                                  <FormControl isRequired>
                                    <FormLabel htmlFor="email">Email </FormLabel>
                                    <Box>
                                      <SimpleGrid columns={2} gap={2}>
                                        <GridItem
                                          colStart={1}
                                          colEnd={index === 0 ? 12 : 11}
                                          width="100%"
                                        >
                                          <Input
                                            id="email"
                                            name={`mail_ids.${index}.email`}
                                            type="email"
                                            variant="filled"
                                            value={email.email}
                                            onChange={handleChange}
                                          />
                                        </GridItem>
                                        <GridItem colStart={11} colEnd={12}>
                                          {index > 0 && (
                                            <Button
                                              style={{ marginRight: '5px', borderRadius: '50px' }}
                                              onClick={() => {
                                                remove(index);
                                              }}
                                            >
                                              <MinusIcon w={2} h={2} />
                                            </Button>
                                          )}
                                        </GridItem>
                                      </SimpleGrid>
                                    </Box>
                                  </FormControl>
                                </div>
                              ))}
                            <Box>
                              <Button
                                style={{ marginRight: '5px', borderRadius: '50px' }}
                                onClick={() => push({ email: '' })}
                              >
                                <AddIcon w={2} h={2} />
                              </Button>
                              Add additional Emails(cc)
                            </Box>
                          </div>
                        )}
                      </FieldArray>

                      <FormControl isRequired>
                        <FormLabel htmlFor="select">Affected Service </FormLabel>
                        <Select
                          placeholder="Select Service"
                          onChange={handleChange}
                          value={values.service_id}
                          name="service_id"
                          color={'GrayText'}
                        >
                          {webform.services.map((service, i) => {
                            return (
                              <option key={i} value={service.service_id}>
                                {service.alias || service.name}
                              </option>
                            );
                          })}
                        </Select>
                      </FormControl>
                      {webform.input_field.length > 0 && <FormLabel>Details</FormLabel>}
                      {webform.input_field.map((item: IInputField, index: number) => {
                        return (
                          <Box key={index} width={'100%'}>
                            <FormControl isRequired display={'flex'} alignItems={'center'}>
                              <FormLabel htmlFor="select">{item.label}</FormLabel>
                              <Select
                                placeholder={`Select a value`}
                                onChange={handleChange}
                                name={`tags[${index}]`}
                                value={values.tags[index] || ''}
                                color={'GrayText'}
                              >
                                {item.options.map((option: any, i: number) => {
                                  return (
                                    <option key={i} value={`${item.label}:${option}`}>
                                      {option}
                                    </option>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Box>
                        );
                      })}

                      <FormControl isRequired>
                        <FormLabel htmlFor="text">Incident Title </FormLabel>
                        <Input
                          name="incident_title"
                          type="text"
                          variant="filled"
                          value={values.incident_title}
                          onChange={handleChange}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel htmlFor="textarea">Incident Description </FormLabel>
                        <Textarea
                          name="incident_description"
                          value={values.incident_description}
                          placeholder="Describe your incident in detail"
                          onChange={handleChange}
                        />
                      </FormControl>
                      <Box
                        sx={{
                          width: '100%',
                        }}
                      >
                        <Input
                          hidden
                          ref={fileRef}
                          type="file"
                          onChange={event => {
                            setFieldValue('attachment', event.target.files?.[0]);
                          }}
                          variant="filled"
                        />
                        {/* <Button
                          sx={{ padding: '15px' }}
                          onClick={() => {
                            fileRef.current?.click();
                          }}
                        >
                          <AddIcon w={4} h={2} /> Add Attachment
                        </Button>
                        {values.attachment ? (
                          <Preview attachment={values.attachment}></Preview>
                        ) : null} */}
                      </Box>
                      {isHostedURL && (
                        <Center
                          sx={{
                            width: '100%',
                          }}
                        >
                          <ReCAPTCHA
                            key={resetKey}
                            sitekey={AppConfig.recaptcha_sitekey_webforms}
                            onChange={e => onloadCallback(e)}
                            onExpired={handleCaptchaExpired}
                          />
                        </Center>
                      )}
                      {isSuccess && (
                        <Alert status="success">
                          <AlertIcon />
                          Incident Submitted Successfully!
                        </Alert>
                      )}
                      {error.submit && (
                        <Alert status="error">
                          <AlertIcon />
                          Something went Wrong ,try again!
                        </Alert>
                      )}
                      <ButtonGroup
                        spacing="10"
                        textAlign="center"
                        w={'100%'}
                        display="inline-block"
                        marginTop="15px"
                      >
                        <Button
                          type="submit"
                          colorScheme="purple"
                          disabled={isHostedURL && isDisabled}
                        >
                          Submit
                        </Button>
                        <Button
                          type="reset"
                          colorScheme="gray"
                          onClick={handleReset.bind(null, resetForm)}
                        >
                          Reset
                        </Button>
                      </ButtonGroup>

                      {webform.footer_text && (
                        <Text textAlign="center" w={'100%'}>
                          {webform.footer_text}
                        </Text>
                      )}
                      {webform.footer_link && (
                        <Link
                          textAlign="center"
                          w={'100%'}
                          color="teal.500"
                          href={
                            validateUrl(webform.footer_link)
                              ? webform.footer_link
                              : `https://${webform.footer_link}`
                          }
                          display={'block'}
                          isExternal
                        >
                          {webform.footer_link}
                        </Link>
                      )}
                    </VStack>
                  </Form>
                );
              }}
            </Formik>
          </Box>
          <Box>
            <Flex
              justify="end"
              style={{
                padding: '20px',
                position: 'fixed',
                right: '20px',
                bottom: '3em',
                borderRadius: '20px',
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 11px 0px',
              }}
            >
              Powered by
              <Link href="https://www.squadcast.com/?utm_source=webforms&utm_medium=attribution+element&utm_campaign=webform+attributor">
                <Image
                  src={require('./squadcast_logo.png')}
                  alt="squadcastLogo"
                  width="100px"
                  style={{ marginLeft: '10px', marginTop: '4px' }}
                />
              </Link>
            </Flex>
          </Box>
        </Flex>
      ) : (
        <Flex align="center" justify="center">
          <Center h="720px">
            <Text color={'red'}>{error.get}</Text>
          </Center>
        </Flex>
      )}
    </>
  );
};

export default WebForm;

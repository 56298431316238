import React from 'react';
import WebForm from './component/Form';
function App() {
  return (
    <div className="App">
      <WebForm />
    </div>
  );
}

export default App;
